#main-grid {
    position: relative;
    top: 70px;
}

.bigger {
    font-size: medium;
    stroke-width: 2;
}

.welcome-text{
    margin-left: 10px;
}

h1, h2, h3, h4, h5, span {
    display: inline-block;
} 

.sub-svg{
    vertical-align: sub;
}

.no-padding {
    padding-right: 0;
    padding-left: 0;
}

#datepicker-column {
    align-items: center;
    text-align: center;
}

.bottom-gap {
    padding-bottom: 15px;
}

.date {
    cursor: pointer;
}

.date:hover {
    font-weight: 600;
}

.secondary-text {
    font-weight: 400;
}

.primary-text {
    font-weight: 600;
}

.today {
    padding-right: 0;
}

.table{
    padding-left: 30px;
}

.cds--tile{
    margin: 10px;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.cds--row {
    align-items: center;
}

.icons--dashboard{
    margin-left: 3rem;
}

.important--data{
    font-size: 45px;
    font-weight: 400;
}

.important--data-weather {
    padding-bottom: 10px;
    font-size: 45px;
    font-weight: 400;
}

.important--data-label{
    font-size: 14px;
    color: rgb(122, 122, 122);
    padding-top: 0px;
}

.unit--data-weather{
    color: rgb(45, 44, 44);
    font-weight: 100;
}

.unit--data{
    color: rgb(45, 44, 44);
    font-weight: 300;
}