/* LightingPlan.scss */
.lighting-plan {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .bx--modal-content {
    width: 80%;
    margin: auto;
  }
  
  .bx--modal-container {
    height: 70%;
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bx--modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .bx--btn--primary {
    margin-left: auto;
  }
  .lighting-plan-button {
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
}  