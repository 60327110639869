.btn-updated {
    border-radius: 10px;
    color: black;
    background-color: #d9fbfb;
    font-weight: 600;
}

.btn-updated:hover {
    background-color: #cdfdfd;
    color: black
}

.cds--text-input {
    border-radius: 10px;
    border: none;
}

.link {
    color: #d9fbfb;
}

.main-text {
    background-image: linear-gradient(45deg, rgba(158,240,240,1) 0%, rgba(255,255,255,1) 93%);
    background-size: 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

}