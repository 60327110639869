.bx--structured-list-td{
    font-family: 20px;
}
.headerMain {
    margin: 30px;
    font-size: 60px;
    text-align: center;
    text-emphasis-color: black;

}
.row-no1{
    justify-content: center;
}

.row-no2{
    margin-top: 30px;
}

.hg-theme-default .hg-button{
    font-size: 40px;
}

.inputNumber{
    font-size: 70px;

}

.startScanBtn{
    margin: 10%;
    width:50%;
    height: 20%;
    font-size: 40px;
    text-emphasis: bold;
    justify-content: center;
    background-color: green;

}

.cancelScanBtn{
    margin: 10%;
    width:50%;
    height: 20%;
    font-size: 40px;
    text-emphasis: bold;
    justify-content: center;
}

.inforobot{
    display:inline-block;
}

.code{
    font-size: 40px;

}