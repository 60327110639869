// @import url('https://fonts.googleapis.com/css2?family=Changa:wght@400;700&display=swap');

body {
  margin: 0;
  // font-family: 'Changa', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161616;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
