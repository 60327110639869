.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem; // Add some padding to the container
  }
  
  .button-container > div {
    display: flex;
    justify-content: center;
    margin: 0 1rem; // Add margin to the sides of each button container
  }
  
  .add-harvest-button > .bx--btn, 
  .button-container > div > .bx--btn {
    width: 150px; // Adjust this value to control the width of your buttons
  }
  
  // Optional: Additional styles for the modals
  .add-harvest-modal, .lighting-plan {
    width: 100%;
    max-width: 500px; // Or adjust to fit your design
  }
  
//   .lighting-plan {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
  
//     .day-checkbox {
//       margin-bottom: 10px;
//     }
//   }
.button-container {
  background: radial-gradient(circle at bottom right, rgba(217,251,251,1) 0%, rgba(237,245,255,1) 15%, rgba(255,255,255,1) 25%);
}

