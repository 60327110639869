// Greenhouse.scss

.greenhouse {
  display: flex;
  width: 100%;
  max-width: 100%; // Ensures it doesn't overflow the screen
  overflow-x: auto; // Enables horizontal scrolling if the content is too wide
}

.side {
  display: flex;
  flex-direction: column;
  width: calc(45% - 10px); // Reduced width, accounting for the smaller road
}

.road {
  width: 5%; // Reduced width of the road
  height: 100%;
  min-width: 20px; // Ensures the road is still visible even when the screen is very narrow
  background-color: #c0c0c0; // Gray path representing the road
}

.plot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.2em; // Reduced height of each plot
  border: 1px solid #000;
  margin-bottom: 0.4em; // Reduced margin between plots
}

.plot-number {
  width: 35px; // Reduced width for plot numbers
  text-align: center;
  font-weight: bold;
  font-size: 0.8em; // Reduced font size to fit the smaller area
}

.sub-plot {
  position: relative;
  flex-grow: 1;
  height: 100%;
  max-width: calc(100% / 47 - 1px); // Calculate max width based on number of sub-plots
  border-left: 1px solid #000;

  &:first-child {
    border-left: none;
  }

  &.attention {
    background-color: #ff9999;
  }

  &.green {
    background-color: #99cc99;
  }
}
.popup {
  position: absolute; // Changed to absolute for relative positioning within the component
  width: 200px; // Set a fixed width for the popup
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  top: 0; // Adjust as necessary
  left: 50%; // Adjust to center the popup relative to the hovered element
}
