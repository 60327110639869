.climate-data-modal {
    .bx--modal-content {
      padding: 2rem;
    }
  
    .climate-data-modal-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    .form-field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .form-label {
      font-weight: 500;
    }
  
    .form-input {
      width: 100%;
    }
  
    .bx--file {
      margin-top: 0.5rem;
    }
  }

  /* AddClimateData.scss */

@keyframes checkmark {
    0% { height: 0; width: 0; opacity: 1; }
    20% { height: 0; width: 11px; opacity: 1; }
    40% { height: 20px; width: 11px; opacity: 1; }
    100% { height: 20px; width: 11px; opacity: 1; }
  }
  
  .checkmark-canvas {
    position: relative;
    height: 24px;
    width: 24px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #07a307;
    background: #07a307;
  }
  
  .checkmark-stem {
    position: absolute;
    width: 3px;
    background-color: white;
    animation: checkmark 0.3s ease;
    height: 14px;
    left: 10px;
    top: 6px;
    transform: rotate(45deg);
    animation-delay: 0.1s;
  }
  
  .checkmark-kick {
    position: absolute;
    width: 3px;
    // background-color: white;
    animation: checkmark 0.3s ease;
    height: 6px;
    left: 8px;
    top: 12px;
    transform: rotate(45deg);
    animation-delay: 0.1s;
  }
  